<template>
  <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
    <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
    <div class="content bg-body" ref="basic_pp_body">
      <div class="text-end mb-2">
        <button class="btn border-0" @click="pp_off()">
          <i class="fal fa-times ms-auto text-gray-relative-500"></i>
        </button>
      </div>

      <div class="w-50 mx-auto">
        <div class="position-relative ratio ratio-1x1">
          <div
            class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center"
          >
            <img :src="item_info.image" alt="" width="100%" />
          </div>
        </div>
        <div class="p-2 text-center">
          <div class="fs-px-13 text-truncate">{{ item_info.name }}</div>
          <div class="fs-px-14 mb-2">
            <b>{{ CheckPrice(item_info.price) }}</b> 원
          </div>
        </div>
      </div>

      <div class="w-100 mx-auto">
        <div class="p-2 text-center">
          <div class="flex-between-center">
            <span class="fs-px-14 text-muted">구매매수 </span
            >{{ item_info.amount }} 매
          </div>
          <div class="flex-between-center">
            <span class="fs-px-14 text-muted">판매금액</span>
            {{ CheckPrice(item_info.amount * item_info.price) }} 원
          </div>
          <div class="flex-between-center">
            <span class="fs-px-14 text-muted">할인금액 </span
            >{{
              CheckDiscount(
                item_info.amount,
                item_info.price,
                item_info.discount
              )
            }}
            원
          </div>
          <div class="flex-between-center border-top mt-2 pt-2">
            <span class="fs-px-14 text-muted">결제금액</span>
            <b
              >{{
                TotalValue(
                  item_info.amount,
                  item_info.price,
                  item_info.discount
                )
              }}
              원</b
            >
          </div>
        </div>
      </div>

      <div class="cutting_line border-gray-relative-300 w-100 h-0 mb-4"></div>

      <div class="px-3">
        <b class="fs-px-16">입금 계좌정보 안내</b>
        <div class="text-gray-relative-700 fs-px-14">
          <p class="mb-2 lh-sm mt-2">
            -구매 전 입금금액과 입금계좌 정보를 확인해주시기 바랍니다.
          </p>
          <p class="mb-2 lh-sm">
            -오후 2시 입금건까지 당일 처리되며, 이후 입금건은 다음날 영업시간에
            처리됩니다.
          </p>
          <p class="mb-2 lh-sm">-입금자명과 회원명이 일치해야 합니다.</p>
        </div>
        <div class="p-3 my-3 border text-center border-gray-relative-200">
          <div class="d-flex flex-column">
            <img :src="bank_image" alt="" class="bank_logo" />
          </div>
          <div>
            <h5 class="fw-bold mb-1">{{ bank_account }}</h5>
            <p>예금주 : {{ bank_holder }}</p>
          </div>
        </div>
        <p class="text-gray-relative-700 fs-px-14">
          입금 정보는 마이페이지의 거래내역에서 확인하실 수 있습니다.
        </p>
      </div>

      <div class="px-3" v-show="item_info.type === 'buy'">
        <div class="text-center my-4">
          <button
            class="btn btn-main thick fs-px-14 w-100 border-0"
            @click="BuyCoupon()"
          >
            구매
          </button>
        </div>
      </div>

      <div class="p-3" v-show="item_info.type === 'gift'">
        <b class="fs-px-14">선물받을 분</b>
        <p class="fs-px-12 text-gray-relative-500 mb-2">
          선물받을 분의 정보를 오차 없이 기입해주세요.
        </p>
        <div class="mb-3">
          <button
            class="btn border-0 fw-bold me-2 fs-px-14"
            :class="{
              'btn-main text-white': gift_type === 0,
              'bg-gray-relative-300': gift_type !== 0,
            }"
            @click="gift_type = 0"
          >
            ID
          </button>
          <span
            class="btn border-0 fw-bold fs-px-14"
            :class="{
              'btn-main text-white': gift_type === 1,
              'bg-gray-relative-300': gift_type !== 1,
            }"
            @click="gift_type = 1"
            >휴대폰</span
          >
        </div>
        <div class="input-group">
          <input
            type="text"
            class="form-control thick bg-body text-body border-gray-relative-200"
            placeholder="ID를 입력해주세요."
            v-if="gift_type === 0"
          />
          <input
            type="text"
            class="form-control thick bg-body text-body border-gray-relative-200"
            placeholder="휴대폰 번호 '-' 없이 입력해주세요."
            v-else
          />
        </div>
        <div class="text-center my-3">
          <button
            class="btn btn-main thick fs-px-14 w-100 border-0"
            @click="GiftCoupon()"
          >
            선물하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const exactMath = require('exact-math');
const CryptoJS = require('crypto-js');
export default {
  data() {
    return {
      gift_type: 0,

      // front ui
      pp: false,
      off: false,
      timeout_id: null,
      bank_image: '',
      bank_account: '',
      bank_holder: '',
      bank_code: '',
      total: '',
      discount: '',
    };
  },
  props: {
    item_info: {
      type: Object,
      default: () => {
        return {
          img: '',
          name: '',
          price: 0,
          amount: 1,
          discount: 0,
        };
      },
    },
  },
  mounted() {
    const login = this.$store.state.login;

    if (login == true) {
      this.GetBankInfo();
    }
  },
  methods: {
    GiftCoupon() {
      const info = this.item_info;
      const type = info.type;
      const code = info.code;
      const price = info.price;
      const bank_account = this.bank_account;
      const bank_holder = this.bank_holder;
      const bank_code = this.bank_code;
      const bank_image = this.bank_image;

      const body = {
        code,
        price,
        bank_account,
        bank_holder,
        bank_code,
        type,
        bank_image,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/GiftTicket', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            this.$alert('상품권 구매 신청하였습니다.').then(() => {
              this.$router.push('/mypage/history');
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },

    BuyCoupon() {
      const info = this.item_info;
      const type = info.type;
      const code = info.code;
      const amount = info.amount;
      const price = info.price;

      const total = this.total;
      const bank_account = this.bank_account;
      const bank_holder = this.bank_holder;
      const bank_code = this.bank_code;
      const bank_image = this.bank_image;

      const body = {
        code,
        price,
        amount,
        bank_account,
        bank_holder,
        bank_code,
        type,
        bank_image,
        total,
      };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/BuyTicket', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            this.$alert('상품권 구매 신청하였습니다.').then(() => {
              this.$router.push('/mypage/history');
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },
    CheckPrice(price) {
      const amount = price * 1;
      return amount.toLocaleString();
    },
    CheckDiscount(amount, price, discount) {
      if (amount == undefined) {
        if (price == undefined) {
          return false;
        }
      }

      const total = exactMath.mul(amount, price);

      const p_discount = exactMath.mul(total, discount, 0.01);

      return p_discount.toLocaleString();
    },
    TotalValue(amount, price, discount) {
      if (amount == undefined) {
        if (price == undefined) {
          return false;
        }
      }

      const total = exactMath.mul(amount, price);

      const p_discount = exactMath.mul(total, discount, 0.01);

      const total_value = exactMath.sub(total, p_discount);
      this.total = total_value;
      return total_value.toLocaleString();
    },
    GetBankInfo() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/shop/GetBankInfo', { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.bank_account = body.info.account;
            this.bank_holder = body.info.holder;
            this.bank_image = body.info.image;
            this.bank_code = body.info.code;
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },

    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}
.bank_logo {
  width: 150px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 10px;
}
.basic_pp .content {
  max-height: 90vh;
}

.ratio-1x1 {
  --bs-aspect-ratio: 45% !important;
}
</style>
